*,
html,
body
{
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    margin:0;
    padding:0;  
}

body
{
    overflow-y: hidden;
}



p
{
    font-size: 21px;

}

a
{
    font-size: 21px;
}



a:visited
{
    color: blue;
}

@media screen and (max-width:768px)
{
    body
    {
        overflow-y: scroll;
    }

    p
    {
        font-size: 15px;

    }

    a
    {
        font-size: 15px;
    }
}
