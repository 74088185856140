.categoria-acordion
{
    padding:0 2%;
}

.categoria-titulo
{
    color: black;
    font-weight: bold;
    font-size: 26px;
    margin: 0;
    color: black;
    text-shadow:
       1px 1px  0 white,
            1px     1px 0 white,  
       1px      1px 0 white,
            1px 1px 0 white,
        1px 1px 0 white;
    /*-webkit-text-stroke: 1px white;*/
}
