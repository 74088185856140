.float{
    position:fixed;
    bottom:40px;
	right:20px;
	width:55px;
	height:55px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
    font-size:30px;
    z-index:2;
}

.my-float
{
	margin-top:13px;
}

iframe
{
	border:0;
}