.home-container
{
    display: grid;
    grid-template-columns: auto 22.5vw;
    
}

.Container-carritoDesktop
{    
    height: 100vh;
    padding:7.5px;
    margin-left: 10px;
    -webkit-box-shadow: 0px 0px 20px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 20px -6px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 20px -6px rgba(0,0,0,0.75);
    overflow-y: scroll;
}

.Total-carritoDesktop
{
    margin-right: 5px;
    margin-top: 10px;
    text-align: end;
}

.Nuevo-pedido-label
{
    background: rgb(224, 224, 224);
    border-radius: 5px;
    line-height: 2.2;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    max-height: 100%;
    place-content: stretch center;
    align-items: stretch;
}
