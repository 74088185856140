.Container-carritoMobile
{

}

.container-carrito-mobile
{
    padding:20px
}

.carrito-item-container
{
    margin: 10px 0;
    display: grid;
    grid-template-columns: 90% 10%;
    place-items: center;
}

.carrito-item-imagen
{
    width: 100%;
}

.carrito-item-detalles
{
    justify-self: start;
}

.carrito-item-nombre
{
    text-align: start;
}

.carrito-container-precio
{
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 1fr;
    margin:10px 0;
}
