.producto-container
{
    background-color: white;
    cursor: pointer;
    display: grid;
    min-height: 100%;
    grid-template-columns: 15% auto;
    grid-template-rows: 1fr;
    border: 1px solid gray;
    border-top: none;
    padding:5px;
    align-items: self-start;
    flex-basis: content;
}



.producto-imagen
{
    width: 100%;
    height: auto;
    object-fit: contain;
}
.producto-detalles
{
    padding:5px;
    display: grid;
    grid-template-columns: 80% 20%;
    grid-template-rows: 1fr;
}

.producto-nombre
{
    font-weight: 600;
    height: min-content;
}

.producto-precio
{   
    margin-left: auto;
    font-weight:600;
    text-align: end;
    height: min-content;
}

.producto-descripcion
{
    margin: 0 5px;
    color: rgb(99, 99, 99);
    grid-column: 2 span;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}


.Cantidad-productoModal
{
    display: flex;
}

.Cantidad-productoModal
{
    font-size: 20px;
    margin: 0;
}

@media screen and (max-width: 678px) 
{
    .producto-container
    {
        grid-template-columns: 25% auto;
    }
}


.Empanada-container
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin:15px 0;
}