.Container-productoCarrito
{
    margin:5px 0;
    display: flex;
}


.Titulo-productoCarrito
{
    margin-right: 25px;
}

.Precio-productoCarrito
{
    margin:0 auto ;
}

.Container-detallesCarrito
{
    background-color: #F5F5F5;
    border-radius: 5px;
    width: max-content;
    flex:1;
    padding:16px;
    margin:10px 0;
}

.Imagen-productoCarrito
{
    display: flex;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit:cover ;
}

.Container-removeIcon
{
    cursor: pointer;
    color:red;
    margin-left: auto;
    justify-self: end;
}