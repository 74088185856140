.appbar-container
{
    background-color:white;
    display:flex;
}

.appbar-title
{
    align-self:center;
    margin:10px;
}

.appbar-mobile-carrito
{
    cursor: pointer;
    margin: auto 30px auto auto;
    font-size: 40px !important;
}